
/* Fonts */
@font-face {
  font-family: 'somaticrounded';
  src: url('somatic-rounded-webfont.woff2') format('woff2'),
       url('somatic-rounded-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logoHeight {
  height: 0;
  padding-top: 35.25%;
  /* background-size: 23% !important; */
}

.alignRight {
  text-align: right !important;
  text-align: right;
float: right
}


.cardGridPadding {
padding: 10px;
text-align: center;
}

.gridPadding {
  padding: 20px;
  text-align: center;
}

.ownFont {
  font-family: 'somaticrounded', Arial, sans-serif;
  color: black;
  font-size: 1.4em;

}